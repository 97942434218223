body {
  margin: 0;
  font-family: roboto-font;
  -webkit-font-smoothing: antialiased;
  text-transform: capitalize !important;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: roboto-font;
}

.highlight {
  background-color: yellow; /* Choose your highlight color */
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 3330 !important;
}

.css-1jz2c3-MuiTypography-root {
  background-color: transparent;
}

.MuiAutocomplete-listbox {
  background-color: aliceblue !important;
}
