@font-face {
  font-family: "roboto-font";
  src:
    local("roboto-font"),
    url(../fonts/comic-sans-ms/Roboto-Regular.ttf) format("truetype");
  font-weight: 400;
}

.highlight {
  background-color: yellow !important; /* Choose your highlight color */
}

.number-type input {
  text-align: right !important;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: white;
}

.shop-name,
.account-name,
.date {
  font-size: 16px;
}

span {
  font-weight: bold;
}

#current-date {
  font-size: 14px;
}

@font-face {
  font-family: "roboto-bold";
  src:
    local("roboto-bold"),
    url(../fonts/comic-sans-ms/Roboto-Bold.ttf) format("truetype");
  font-weight: 700;
}

.css-ttencl-MuiListItemIcon-root {
  min-width: 45px !important;
}

.MuiFormHelperText-root {
  margin-top: -6px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.css-1ew6aww-MuiTypography-root {
  background-color: inherit !important;
}

.css-14uc35n-MuiGrid-root > .MuiGrid-item {
  margin-top: 10px !important;
  padding-top: 10px !important;
}

/* * input {
  text-align: center !important;
} */

/* .payment-slip input {
  text-align: left !important;
}

.amount-showing input {
  text-align: right !important;
}

.SignInField input {
  text-transform: none !important;
} */

.sign_in_text_field {
  margin: 0px !important;
  padding: 0px !important;
  margin-top: 10px !important;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Adjust the opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Make sure it's on top of everything */
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiTypography-caption {
  font-size: 16px !important;
}

.css-1kqk7c3-MuiFormHelperText-root {
  padding-right: 0px !important;
}

.Mui-error {
  padding-right: 0px !important;
  margin-left: 0px !important;
}

.date-range-input {
  display: flex;
  align-items: center;
  border: 1px solid #d4d4d4;
  height: 42px;
  background: transparent;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
}

.date-range-calendar {
  margin-right: 10px;
}

.date-input {
  border: 0;
  outline: 0;
  background: transparent;
  padding: 5px;
  width: 100px;
}

.dash {
  padding: 0 5px;
  font-weight: bold;
}

.MuiTableCell-root {
  padding-top: 10px !important;
  padding-bottom: 4px !important;
}
